import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The Gabii Project employs a single-context, open-area excavation and recording system based on the Barker-Harris methodology required by the Catalogue Office of the Ministero dei Beni Culturali. Each stratigraphic unit (SU), the basic unit of information in this system, is uniquely numbered, and its characteristics are recorded through a variety of methods. The record comprises written descriptions, photographs, and measurements. Stratigraphic units may have associated samples, which, in turn, have descriptions, photographs, and measurements. To manage the large volumes of data produced by excavation at this scale and to create a detailed record, the Gabii Project emphasizes field and lab methods that support rapid yet accurate recording and data integration. Our recording strategy aims to unite spatial and descriptive information produced across the site and to closely integrate data produced by various specialist teams.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Descriptive Data`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`The basic descriptive data recorded for the Gabii Project has remained by and large consistent over the lifetime of the project, while the means of recording and managing the data has evolved. In 2009, descriptive data was recorded on paper context forms. The context forms were modeled closely on those used by the Italian Catalogue Office of the Ministero dei Beni Culturali, to ensure the required information was collected. Information recorded on the context forms was subsequently entered into a Microsoft Access database. In 2013, the project migrated to a customized ARK database. The ARK database system, developed by LP Archaeology (`}<MDXTag name="a" components={components} parentName="p" props={{"href":"http://ark.lparchaeology.com/"}}>{`http://ark.lparchaeology.com/`}</MDXTag>{`), is used by a number of Mediterranean archaeology projects (e.g., Chersonesos, Villa Magna, Fasti Online). During this transition, paper record sheets continued in use, and data was subsequently entered into the ARK database. In 2014, the project moved to a direct-to-digital workflow, using Android- and Windows-based tablets for descriptive data input directly into the project’s database and ilestore while in the field. Along with this descriptive data, photographs, artifact collections, interpretations, samples, and spatial data are linked to the unique context number of the SU. A Harris matrix updated in real time within the database helps in keeping track of the evolving stratigraphy across each area. Specialist labs (e.g., finds, palaeobotany) input relevant descriptive data and photographs into their relevant modules in the ARK. All qualitative and spatial data is uploaded daily to the project’s servers at the University of Michigan for backup and for off-site access by project staff.`}</MDXTag>
<MDXTag name="p" components={components}>{`Read more about the Methodology of the Gabii Project here:(`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.fulcrum.org/epubs/bz60cw28v?locale=en#/6/18%5C%5Ba05a_Introduction04%5D!/4/2/2%5C%5Bdiv1_methods%5D/8/1:316"}}>{`https://www.fulcrum.org/epubs/bz60cw28v?locale=en#/6/18`}{`[`}{`a05a_Introduction04]!/4/2/2`}{`[`}{`div1_methods]/8/1:316`}</MDXTag>{`)`}</MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-with-background","title":"Methodology","backgroundImage":"/assets/pasted-image-0.png","key":"methodology","parentKey":"the-gabii-project"};

  